// This code is intended for demonstration only in the development environment and will be removed after the demo.

import React, { useState } from "react";
import "./BTSTestRegistration.scss";
import forge from "node-forge";

const BTSRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organizationName: "",
    organizationType: "",
    salutation: "",
    phone: "",
    title: "",
    assistantEmail: "",
    integrationAccountId: "61oki4pv4q857mcjbrttct2uu4",
    externalEventId: "",
    externalMeetingId: "",
    externalAttendeeId: "",
    integratorId: "BTS",
    privateKeyBase64: "",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name !== "integratorId") {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const getPrivateKeyFromBase64 = (privateKeyBase64: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const privateKeyPem = `-----BEGIN PRIVATE KEY-----\n` + privateKeyBase64.match(/.{1,64}/g)!.join('\n') + `\n-----END PRIVATE KEY-----`;
    const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
    return privateKey;
  };

  const signData = (data: string, privateKey: forge.pki.PrivateKey) => {
    const md = forge.md.sha256.create();
    md.update(data, "utf8");
    const signature = privateKey.sign(md);
    return forge.util.encode64(signature);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newErrors: any = {};
    const requiredFields = ["firstName", "lastName", "email", "organizationName", "integrationAccountId", "externalEventId", "privateKeyBase64", "externalAttendeeId"];

    requiredFields.forEach((key) => {
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setLoading(true);
    setSubmissionStatus(null);

    try {
      const { privateKeyBase64, ...filteredData } = formData;
      const nonEmptyData = Object.fromEntries(
        Object.entries(filteredData).filter(([_, value]) => value !== "")
      );
      const jsonData = JSON.stringify({ ...nonEmptyData });
      const privateKey = getPrivateKeyFromBase64(formData.privateKeyBase64);
      const signature = signData(jsonData, privateKey);

      const response = await fetch(
        "https://passport-api.dev-open-exchange.net/attendee/one-way-register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Signature": signature,
            "X-Api-KeyId": "badf8735-5b62-4c67-aa17-5c060531b0b9",
          },
          body: jsonData,
        }
      );

      if (response.status === 200) {
        const locationHeader = response.url;
        if (locationHeader) {
          window.location.href = locationHeader;
          return;
        }
        throw new Error("302 response but no Location header found.");
      }

      if (!response.ok) {
        if (response.status === 403) {
          throw new Error("CORS issue or forbidden request.");
        }
        throw new Error("Network response was not ok");
      }
      setSubmissionStatus("Success! Your data has been submitted.");
    } catch (error) {
      let errorMessage = "Error! There was an issue submitting your data.";
      if (error.message.includes("CORS")) {
        errorMessage = "CORS issue detected. Please contact support.";
      }
      setSubmissionStatus(errorMessage);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="form-container">
      <h1>BTS Registration Form</h1>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div className="form-group" key={key}>
            <label htmlFor={key}>{formatLabel(key)}</label>
            <div className="form-field">
              {key === "privateKeyBase64"
                ? (
                  <textarea
                    id={key}
                    name={key}
                    value={formData[key as keyof typeof formData]}
                    onChange={handleChange}
                    rows={10}
                  />
                )
                : (
                  <input
                    id={key}
                    name={key}
                    type={
                      key.includes("Email")
                        ? "email"
                        : key.includes("Phone")
                          ? "tel"
                          : "text"
                    }
                    value={formData[key as keyof typeof formData]}
                    onChange={handleChange}
                  />
                )}
              {errors[key] && <p className="error">{errors[key]}</p>}
            </div>
          </div>
        ))}
        <div className="form-group">
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
      {submissionStatus && (
        <p className="submission-status">{submissionStatus}</p>
      )}
    </div>
  );
};

const formatLabel = (key: string) => {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

export default BTSRegistrationForm;
