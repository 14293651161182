import { ResponseError } from '../../../config/api';
import { useSWR, responseInterface } from '../../../config/overrides/swr';

import API, { PUBLIC } from '../../../lib/api';
import { getCurrentPathName, getFileNameFromUrl, getFileDispositionFromUrl } from '../../../lib/api/utils';

const { fetchFileDownloadUrlByPublicIdentifier } = API[PUBLIC];

export function useAssetDownloadUrl(): responseInterface<{ signedUrl: string }, ResponseError> {
  const publicIdentifier = getCurrentPathName();
  const fileName = getFileNameFromUrl();
  const fileDisposition = getFileDispositionFromUrl();
  const keyFn = () => publicIdentifier ? [publicIdentifier, fileName, fileDisposition] : null;

  return useSWR(keyFn, fetchFileDownloadUrlByPublicIdentifier);
}
