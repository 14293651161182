import React, { useEffect, useState } from 'react';
import { PrivateMeetingDetailsResponseType } from '../../../../lib/api';
import { JoinMethod } from '../../../../lib/join-method/join-method';
import { Connection } from '../../../../lib/connection/connection';
import DefaultSpinner from '../../../shared/DefaultSpinner';
import ConnectToMeeting, { ZOOM_REGISTRATION_IN_PROGRESS_TEXT } from '../ConnectToMeeting';
import OpenPresenterLink from '../OpenPresenterLink';
import { useWebinarRegistrationSubscription } from '../../../../hooks/use-webinar-registration-subscription';
import { utcToZonedTime } from 'date-fns-tz';
import { fetchWebinarRegistrationSubscriptionParams, registerToWebinar } from '../../../../lib/api/protected';
import { ERR_MODAL_COMMON_STYLES } from '../../../../lib/constants';
import { Button } from '../../../../components/_base/Button';
import Modal from 'react-modal';

export type ConnectionContentItemProps = {
  eventId: string;
  timeZone: string;
  meetingDetails: PrivateMeetingDetailsResponseType;
  isConnectBtnDisabled: boolean;
  joinMethod: JoinMethod | null;
};

export const ConnectionContentItem: React.FunctionComponent<ConnectionContentItemProps> = (props) => {
  const {
    eventId, meetingDetails, isConnectBtnDisabled, joinMethod, timeZone
  } = props;

  const { state, subscribe } = useWebinarRegistrationSubscription();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const openErrorModal = () => setIsErrorModalOpen(true);
  const closeErrorModal = () => setIsErrorModalOpen(false);

  const connection = Connection.create(meetingDetails);

  const connectionDetails = !meetingDetails.registrationNeeded && (joinMethod ? joinMethod.meetingInvitation : meetingDetails.connectionDetails);
  const isKnovioConnectionPublish = joinMethod?.isKnovioConnectionType() ? joinMethod.isKnovioAvailable : true;
  const isMultipleConnection = connection?.isMultipleConnection() as boolean;
  const isRegistrationNeeded = meetingDetails.registrationNeeded || joinMethod?.isRegistrationNeeded;

  const attendeeEndDateTime = utcToZonedTime(meetingDetails.endDateTime, timeZone);
  const isMeetingInThePast = attendeeEndDateTime < new Date();

  const joinUrl = state.joinUrl || null;

  useEffect(() => {
    const preregisterToWebinar = async () => {
      let registrationId = '';
      const connectionId = connection?.getConnectionId();
      const joinMethodId = joinMethod?.joinMethodId;

      try {
        const {
          registrationRequired,
          ...registrationSubscriptionParams
        } = await fetchWebinarRegistrationSubscriptionParams(eventId, meetingDetails.meetingId, connectionId, joinMethodId);
        registrationId = registrationSubscriptionParams.registrationId;

        await subscribe(registrationSubscriptionParams, joinMethod?.isAudioBridgeType());
        if (registrationRequired) await registerToWebinar(eventId, meetingDetails.meetingId, registrationId, connectionId, joinMethodId);
      } catch (e) {
        if (e?.message === 'ConnectionDisrupted') {
          openErrorModal();
          registerToWebinar(eventId, meetingDetails.meetingId, registrationId, connectionId, joinMethodId);
        }
      }
    };

    if (isRegistrationNeeded && !isMeetingInThePast) {
      preregisterToWebinar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!isKnovioConnectionPublish) {
    return (
      <div className='m-8' data-test-id='placeholder-text'>
        <div dangerouslySetInnerHTML={{ __html: joinMethod?.detailsPlaceholderText ?? '' }} />
      </div>
    );
  }

  if (state.inProgress && isRegistrationNeeded) {
    return (
      <div className='bg-white m-8 p-8 shadow-gray'>
        <DefaultSpinner visible={true} />
        <div className='pt-4 whitespace-pre-wrap leading-loose'>{ZOOM_REGISTRATION_IN_PROGRESS_TEXT}</div>
      </div>
    );
  }

  if (state.uniqueDialInCode) {
    joinMethod?.setUniqueDialInCode(state.uniqueDialInCode);
  }

  return (
    <div>
      <div className='text-left p-8'>
        <ConnectToMeeting {...{ ...meetingDetails, isConnectBtnDisabled, joinMethod, isMultipleConnection, joinUrl }} />
        {!isMultipleConnection && <OpenPresenterLink presenterPortalLink={meetingDetails.presenterPortalLink} />}
      </div>
      {connectionDetails && !isConnectBtnDisabled && !isRegistrationNeeded && (
        <div className='px-8 font-size-14px'>
          <div className='connection-details-title font-semibold'>Connection Details</div>
          <div className='pt-4 whitespace-pre-wrap leading-loose'>
            {connectionDetails}
          </div>
        </div>
      )}
      <Modal style={ERR_MODAL_COMMON_STYLES} isOpen={isErrorModalOpen} ariaHideApp={false}>
        <div className="modal-wrapper flex items-center flex-col">
          <p className="mb-4">
            Your system settings do not allow us to update your connection details automatically.
            Please refresh the page in a few moments to see details of how to connect to this meeting.
          </p>
          <Button
            size={'big'}
            name='ok'
            onClick={closeErrorModal}
            label='OK'
          />
        </div>
      </Modal>
    </div>
  );
};
