import React from 'react';
import { PrivateMeetingDetailsResponseType } from '../../../../lib/api';
import { Connection } from '../../../../lib/connection/connection';
import './ConnectionWrapper.scss';
import { ConnectionContentItem } from './ConnectionContentItem';
import OpenPresenterLink from '../OpenPresenterLink';

export type ConnectionWrapperProps = {
  meetingDetails: PrivateMeetingDetailsResponseType;
  isConnectBtnDisabled: boolean;
  timeZone: string;
  eventId: string;
}

const ConnectionWrapper: React.FunctionComponent<ConnectionWrapperProps> = ({ meetingDetails, timeZone, isConnectBtnDisabled, eventId }) => {
  const connection = Connection.create(meetingDetails);
  const connectionArray = connection?.getJoinMethods();

  return (
    <div className='flex-equal sm:flex-1 md:w-1/2 overflow-visible bg-cool-gray'>
      {(!connection || !connectionArray)
        ? (
          <ConnectionContentItem
            joinMethod={null}
            timeZone={timeZone}
            isConnectBtnDisabled={isConnectBtnDisabled}
            meetingDetails={meetingDetails}
            eventId={eventId}
          />
        )
        : <>
          {
            connectionArray.map((joinMethod, index) =>
              (
                <ConnectionContentItem
                  joinMethod={joinMethod}
                  timeZone={timeZone}
                  isConnectBtnDisabled={isConnectBtnDisabled}
                  meetingDetails={meetingDetails}
                  eventId={eventId}
                  key={index}
                />
              )
            )
          }
          <div className='text-left p-8'>
            <OpenPresenterLink presenterPortalLink={meetingDetails.presenterPortalLink} />
          </div>
        </>
      }
    </div>
  );
};

export default ConnectionWrapper;
