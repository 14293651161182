import React from 'react';
import { useHistory } from 'react-router-dom';
import { getConfigByPath } from '../../hooks/use-navbar-items';
import { PrivateMeetingDetailsResponseType, SpeakerListResponseType, SpeakerResponseType } from '../../lib/api';
import { EventSettingsContextType, useEventSettings } from '../../lib/context-providers/event-settings-context/event-settings-context';
import MeetingTimingBlock from './MeetingTimingBlock';
import OrganizationsList from '../../app/shared/OrganizationsList';
import { AGENDA_PATH, REPLAYS_PATH, SESSION_PATH } from '../../config/routes/paths';
import { Button } from '../../components/_base/Button';
import Icon from '../../components/_base/Icon';
import './MeetingDetails.scss';
import Buttons from '../../components/button';
import useMeetingCalendar from '../../hooks/use-meeting-calendar';
import OrganizationsListWithDetails from './OrganizationListWithDetails/OrganizationListWithDetails';
import useEventToken from '../../hooks/use-event-token';
import MeetingDetailsLinks from './MeetingDetailsLinks/MeetingDetailsLinks';
import ConnectionWrapper from '../passport/MeetingWrapper/ConnectionWrapper/ConnectionWrapper';

export interface IProps {
  meetingDetails: PrivateMeetingDetailsResponseType;
  isConnectBtnDisabled: boolean;
  timeZone: string;
  showTopNavBtns?: boolean;
  agendaLabel?: string;
  eventName?: string;
  eventId: string;
  showAddToCalendarBtn?: boolean;
  eventCustomTitle?: string
  className?: string;
  singleMeetingPath?: string;
  meetingLinksLabel?: string;
}

export const PREVENTED_DETAILS_TEXT = 'Connection Details will be provided before this meeting begins.';

const MeetingDetails: React.FunctionComponent<IProps> = (props: IProps) => {
  const data = useEventSettings() as EventSettingsContextType;
  const eventToken = useEventToken(Number(props.eventId));
  const history = useHistory();
  const { fetchOutlookCalendarForMeeting, fetchGoogleCalendarForMeeting } = useMeetingCalendar();

  let path = AGENDA_PATH;

  if (history.location.state === SESSION_PATH) path = SESSION_PATH;
  if (history.location.state === REPLAYS_PATH) path = REPLAYS_PATH;

  const { label } = (path && data && getConfigByPath(path, data)) || { label: props.agendaLabel ?? 'Agenda' };

  const backToList = () => history.push(path || AGENDA_PATH);

  const { meetingDetails: details, isConnectBtnDisabled, singleMeetingPath, meetingLinksLabel } = props;

  const attendeesWithDetails: SpeakerListResponseType = [];
  /* eslint-disable @typescript-eslint/no-unused-expressions */
  [
    ...(props.meetingDetails.hostOrganizations ?? []),
    ...(props.meetingDetails.participantOrganizations ?? []),
    ...(props.meetingDetails.presenterOrganizations ?? [])
  ].forEach(organization => {
    organization.attendees?.map(attendee => {
      if (attendee.biography || attendee.profileImageUrl) {
        const attendeeOrganization = attendeesWithDetails.find(el => el.organizationId === organization.organizationId);
        attendeeOrganization ? attendeeOrganization.speakers?.push(attendee as SpeakerResponseType) : attendeesWithDetails.push({ ...organization, speakers: [attendee as SpeakerResponseType]});
      }
      return attendee;
    });
    return organization;
  });

  return (
    <div className='md:w-3/4 w-100'>
      <div className={`shadow-gray bg-white mx-auto md:px-8 px-0 p-8 mt-12 ${props.className ?? ''}`}>
        {props.showTopNavBtns && <>
          <div className='px-8 pb-8'>
            <Button
              name='backToList'
              type='button'
              className='py-1 px-2 rounded-md text-primary bg-transparent on-hover-shadow'
              onClick={backToList}
              label={
                <>
                  <span className='pr-1'>
                    <Icon.RightArrow
                      className='mb-1 fill-primary transform rotate-180'
                      width='15px'
                      height='15px'
                    />
                  </span>
                  <span className='font-size-13px'>{label}</span>
                </>}
            >
            </Button>
          </div>
          <hr />
        </>}

        <div className='block sm:flex flex-none sm:flex-row items-start items-stretch max-h-full min-h-full'>
          <div className='flex-equal sm:flex-1 px-6 md:px-0 py-6 sm:pb-0 overflow-visible md:w-1/2'>
            <div className='pt-2 xs:pt-8 pb-5 sm:pl-8 sm:pr-12'>
              <MeetingTimingBlock meetingDetails={props.meetingDetails} timeZone={props.timeZone} />
              {props.meetingDetails.type && <p className='text-sm'>
                <span className='text-primary text-xs font-bold'>Meeting type:&nbsp;</span>
                <span className='text-xs'>{props.meetingDetails.type}</span>
              </p>}
            </div>
            {(props.meetingDetails.topic || props.meetingDetails.notes) && <div className='pt-3 pb-5 px-0 sm:pl-8 sm:pr-12'>
              <h2 className='text-primary font-semibold font-size-18px pb-1'>{props.meetingDetails.topic}</h2>
              <p className='text-sm whitespace-pre-wrap break-words' dangerouslySetInnerHTML={{ __html: props.meetingDetails.notes }} />
            </div>}
            {props.meetingDetails.heroImageUrl &&
              <div className='mb-5 sm:ml-8 sm:mr-12'>
                <img src={props.meetingDetails.heroImageUrl} alt='hero'/>
              </div>}
            {props.showAddToCalendarBtn &&
              <div className='add-to-calendar-btn--wrapper sm:pl-8'>
                <Buttons.OptionsButton
                  label={'+ Add to Calendar'}
                  name={'addToCalendar'}
                  disabled={false}
                  eventId={props.eventId ?? ''}
                  eventName={props.eventName ?? ''}
                  className='select-box--button-small'
                  downloadIcsFile={() => fetchOutlookCalendarForMeeting(
                    props.eventId ?? '',
                    details.meetingId,
                    `${props.eventCustomTitle || props.eventName}.ics`,
                    eventToken,
                    singleMeetingPath
                  )}
                  openGoogleCalendar={() => fetchGoogleCalendarForMeeting(
                    props.eventId ?? '',
                    details.meetingId,
                    eventToken,
                    singleMeetingPath
                  )}
                />
              </div>
            }
            {props.meetingDetails.presenterOrganizations &&
              <OrganizationsList
                blockName=''
                organizations={props.meetingDetails.presenterOrganizations}
              />}
            {props.meetingDetails.participantOrganizations &&
              <OrganizationsList
                blockName={props.meetingDetails.presenterOrganizations ? 'Attendees:' : ''}
                organizations={props.meetingDetails.participantOrganizations}
              />}
            {props.meetingDetails.hostOrganizations &&
              <OrganizationsList
                blockName='Hosted by:'
                organizations={props.meetingDetails.hostOrganizations}
              />}
            {props.meetingDetails.links.length > 0 &&
              <MeetingDetailsLinks
                links={props.meetingDetails.links}
                meetingLinksLabel={meetingLinksLabel}
              />}
            {attendeesWithDetails.length > 0 &&
              <OrganizationsListWithDetails
                organizations={attendeesWithDetails}
              />}
          </div>
          <ConnectionWrapper
            meetingDetails={props.meetingDetails}
            isConnectBtnDisabled={isConnectBtnDisabled}
            timeZone={props.timeZone}
            eventId={props.eventId}
          />
        </div>
        {!props.meetingDetails && <p className='text-center pt-4 pb-8'>No meeting details yet</p>}
      </div>
    </div>
  );
};

MeetingDetails.defaultProps = {
  isConnectBtnDisabled: true
};

export default MeetingDetails;
